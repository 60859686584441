import { RawLocation } from 'vue-router'
import { auth, hasFeatures } from '@middleware'
import routesFactory from '@router/routes-factory'
import { INSIGHT_SCOPES } from '../utils'

const routerConfig = {
    baseURI: 'insights/content',
    module: 'Insights',
    middleware: [
        auth('admin', { name: '401' }),
        hasFeatures('InsightsContent', {
            name: '403',
            params: {
                message: 'INSIGHTS.403.MESSAGE',
            },
        }),
    ],
}

export default routesFactory(routerConfig, [
    /**
     * Content master pages
     */
    {
        path: '',
        name: 'insights.content.index',
        redirect: {
            name: INSIGHT_SCOPES.get('CONTAINERS')?.routeName,
            query: {
            },
        },
        props: {
            nav: {
                'ACADEMY.TITLE': INSIGHT_SCOPES.get('CONTAINERS')?.routeName,
                'TERMS.MODULES': INSIGHT_SCOPES.get('MODULES')?.routeName,
            },
            header: {
                title: 'INSIGHTS.CONTENT.TITLE',
                description: 'INSIGHTS.CONTENT.INFO',
            },
            persistentQueries: ['completion'],
        },
        component: (): Promise<any> => import('../InsightsGroupMaster.vue'),
        children: [
            {
                path: 'modules',
                name: INSIGHT_SCOPES.get('MODULES')?.routeName,
                component: (): Promise<any> => import('../InsightsContentModulesIndex.vue'),
            },
            {
                path: 'learning-paths',
                name: INSIGHT_SCOPES.get('CONTAINERS')?.routeName,
                component: (): Promise<any> => import('../InsightsContentContainersIndex.vue'),
            },
        ],
    },

    /**
     * Module insights routes
     */
    {
        path: 'modules/:moduleId',
        name: 'insights.content.module.index',
        redirect: (to): RawLocation => ({
            name: INSIGHT_SCOPES.get('MODULE_USERS')?.routeName,
            query: {
                'is-active': '1',
                ...to.query,
            },
        }),
        props: {
            nav: {
                'TERMS.USERS': INSIGHT_SCOPES.get('MODULE_USERS')?.routeName,
                'STRUCTURE.DIVISIONS.TITLE': INSIGHT_SCOPES.get('MODULE_DIVISIONS')?.routeName,
                'STRUCTURE.JOBPROFILES.TITLE': INSIGHT_SCOPES.get('MODULE_TITLES')?.routeName,
            },
            persistentQueries: ['container', 'is-active', 'from', 'to'],
        },
        component: (): Promise<any> => import('../InsightsGroupMaster.vue'),
        children: [
            {
                path: 'users',
                name: INSIGHT_SCOPES.get('MODULE_USERS')?.routeName,
                component: (): Promise<any> => import('../InsightsContentModuleUsers.vue'),
            },
            {
                path: 'departments',
                name: INSIGHT_SCOPES.get('MODULE_DIVISIONS')?.routeName,
                component: (): Promise<any> => import('../InsightsContentModuleDivisions.vue'),
            },
            {
                path: 'job-profiles',
                name: INSIGHT_SCOPES.get('MODULE_TITLES')?.routeName,
                component: (): Promise<any> => import('../InsightsContentModuleTitles.vue'),
            },
        ],
    },

    /**
     * Container insights routes
     * ? Commented out since we can get the same data on learner insights
     * ? Leaving in if we get some negative feedback from customers
     */
    // {
    //     path: 'learning-paths/:containerId',
    //     name: 'insights.content.container.index',
    //     redirect: (to): RawLocation => ({
    //         name: INSIGHT_SCOPES.get('CONTAINER_USERS')?.routeName,
    //         query: {
    //             'is-active': '1',
    //             ...to.query,
    //         },
    //     }),
    //     props: {
    //         nav: {
    //             'TERMS.USERS': INSIGHT_SCOPES.get('CONTAINER_USERS')?.routeName,
    //             'STRUCTURE.DIVISIONS.TITLE': INSIGHT_SCOPES.get('CONTAINER_DIVISIONS')?.routeName,
    //             'STRUCTURE.JOBPROFILES.TITLE': INSIGHT_SCOPES.get('CONTAINER_TITLES')?.routeName,
    //         },
    //         persistentQueries: ['is-public', 'completion', 'is-active', 'from', 'to'],
    //     },
    //     component: (): Promise<any> => import('../InsightsGroupMaster.vue'),
    //     children: [
    //         {
    //             path: 'users',
    //             name: INSIGHT_SCOPES.get('CONTAINER_USERS')?.routeName,
    //             component: (): Promise<any> => import('../InsightsContentContainerUsers.vue'),
    //         },
    //         {
    //             path: 'departments',
    //             name: INSIGHT_SCOPES.get('CONTAINER_DIVISIONS')?.routeName,
    //             component: (): Promise<any> => import('../InsightsContentContainerDivisions.vue'),
    //         },
    //         {
    //             path: 'job-profiles',
    //             name: INSIGHT_SCOPES.get('CONTAINER_TITLES')?.routeName,
    //             component: (): Promise<any> => import('../InsightsContentContainerTitles.vue'),
    //         },
    //     ],
    // },
])